


















import { Vue, Component } from 'vue-property-decorator';
import { IntegrationCard } from '@/components/integration';
import { Integration } from '@/models';
import { IntegrationsService } from '@/lib/services/integrations';

@Component({
  components: {
    IntegrationCard,
  },
})
export default class AccountantIntegrationsPage extends Vue {
  integrations: Integration[] = [];
  service: IntegrationsService = new IntegrationsService(true);

  async mounted(): Promise<void> {
    await this.refresh();
  }

  async refresh(): Promise<void> {
    this.integrations = await this.service.all();
  }

  update(integrations: Integration[]): void {
    this.integrations = integrations;
  }
}
